import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSidebarOpen } from '@/store/ui.slice';
import { RootState } from '@/store';
import * as S from '@/components';
import { useNavigate } from 'react-router-dom';

import Controls from '@/components/ShowcaseOverlay/Controls';
import Share from '@/components/ShowcaseOverlay/Share';

const ShowcaseOverlay: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSidebarOpen } = useSelector((state: RootState) => state.ui);

  return (
    <div className={'relative h-full touch-none w-full'}>
      <div className={'pointer-events-auto absolute top-0 left-xl'}>
        <Share />
      </div>

      <div className={'pointer-events-auto absolute right-xl top-xl'}>
        <Controls />
      </div>

      <div className={'pointer-events-auto absolute right-xl bottom-xl'}>
        <S.Button
          content={'AR'}
          split={false}
          onClick={() => navigate('/configurator/ar-onboarding')}
          shadow
        />
      </div>
			
      <div
        className={`
            pointer-events-auto absolute bottom-xl left-1/2 -translate-x-1/2 transform lg:hidden
            ${isSidebarOpen ? 'hidden' : 'block'}
          `}
      >
        <S.Button
          content={'Configure'}
          icon={'config'}
          split={false}
          onClick={() => dispatch(setIsSidebarOpen(true))}
          shadow
        />
      </div>
    </div>
  );
};

export default ShowcaseOverlay;
