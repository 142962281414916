import * as S from '@/components';
import Popin from '@/components/Popin/Popin';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { setMode } from '@/store/ar.slice';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'

const AROnboarding = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentProjectCode = useSelector((state: RootState) => state.user.currentProjectCode);

  return (
    <Popin
      width={700}
      isShowing={isVisible}
      hide={() => setIsVisible(false)}
      onHide={() => navigate('/configurator')}
    >
      <div className={'flex flex-col items-center px-[20px] py-[70px] lg:px-[80px]'}>
        <div className={'mb-lg flex gap-x-md text-md font-medium uppercase'}>
          <S.Heading Tag={'h2'} size={'lg'} weight={'bold'}>
            Augmented Reality
          </S.Heading>
        </div>

        <div className={'mb-md flex flex-col gap-y-sm'}>
          <S.Text align={'left'}>
          To view your handling system under optimal conditions :{' '}
            <ul>
              <li><strong>Save your project</strong></li>
              <li>Ensure you are in a well-lit environment</li>
              <li>Choose a device with the best possible performance</li>
            </ul>
          </S.Text>
          <S.Text align={'left'}>
            If you need to visualize your handling system in real size : {' '}
            <ul>
              <li>Make sure you have enough space to install your M-Ub and move around</li>
            </ul>
          </S.Text>
          <S.Text align={'left'}>
            <i>Choose a relative size model if you are experiencing issues displaying a large configuration in a small space.</i>
          </S.Text>
        </div>

        <div className={'flex items-center gap-x-md'}>
          <S.Button
            fill={true}
            variant={'primary'}
            content={'Real size'}
            onClick={() => {
              dispatch(setMode('absolute'));
              navigate(`/ar?code=${currentProjectCode}`);
            }}
          />
          <S.Button
            fill={true}
            variant={'primary'}
            content={'Relative'}
            onClick={() => {
              dispatch(setMode('responsive'));
              navigate(`/ar?code=${currentProjectCode}`);
            }}
          />
        </div>
      </div>
    </Popin>
  );
};

export default AROnboarding;
